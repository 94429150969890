@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,700&display=swap");

body{
  margin: 0;
  padding: 0%;
  font-family: 'poppins',sans-serif;
  background: var(--bg);
  transition: all 3ms ease;
  color: var(--black);
  scroll-behavior: smooth;
}