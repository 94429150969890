.testimonials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
}
.wrappers{
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
}
.wrappers>img{
    width: 25rem;
    justify-self: center;
}
.containerss{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.containerss>:nth-child(1)
{
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
}
.containerss>:nth-child(2){
    font-size: 0.8rem;
    text-transform: uppercase;
    display: block;
}
.containerss1{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: right;
}
.containerss1>:nth-child(1)
{
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
}
.containerss1>:nth-child(2){
    font-size: 0.8rem;
    text-transform: uppercase;
    display: block;
}
.reviews{
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}
.carousel{
    width: 100%;
}
.carousel{
    padding: 2rem;
}
.tCarousel{
    padding: 2rem;
}
.testimonial{
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
}
.testimonial>img{
    position:absolute;
    width: 3rem ;
    top: -1.5rem;
    left: 45%;
}
.testimonial>span:nth-of-type(1){
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin-top: 2rem;
}
.testimonial>hr{
    height: 1px;
    width: 80%;
    background: rgb(198, 198, 198);
    border: none;
}
.testimonial>span:nth-of-type(2){
    font-weight: 500;
}